.vehicle-container select {
  width: 100%;
  color: gray;
}
.specification-remark {
  color: red;
  font-weight: 600;
  margin-top: 10px;
}
.vehicle-main-div {
  background: #e5e5e5;
}
.vehicle-container {
  margin: auto;
  width: 43%;
  height: 110vh;
  background: white;
  padding: 2% 2% 0 2%;
}
.vehicle-container input {
  color: #0c1830 !important;
  height: 44px;
  line-height: 3;
  color: black;
  border: 2px solid #c7c7c7;
  border-radius: 5px;
}
.vehicle-container input:focus {
  border-color: unset !important;
  outline: 0;
  box-shadow: 0 0 0 0 !important;
  border: 2px solid #8fe139 !important;
  position: relative;
  color: black;
}

.vehicle-container select:focus {
  border-color: unset !important;
  outline: 0;
  box-shadow: 0 0 0 0 !important;
  border: 2px solid #8fe139 !important;
  color: black;
}
.css-1pahdxg-control:focus {
  color: #0c1830 !important;
  height: 44px;
  line-height: 3;
  border: 1px solid #c7c7c7 !important;
  border-radius: 0px !important;
}
.vehicle-container select {
  border: 2px solid #c7c7c7;
  height: 44px;
  border-radius: 5px;
  margin-inline-end: 2%;
  position: relative;
  color: gray;
}
.delivery-container select {
  width: 100%;
  color: gray;
}
.spec-title-div {
  margin-block: 3%;
}

.brand-div input:focus {
  border-color: unset !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0 !important;
  border: 0 !important;
  color: black;
}
.brand-div .css-1pahdxg-control:hover {
  color: #0c1830 !important;
  height: 44px;
  line-height: 3;
  border: 1px solid #c7c7c7 !important;
  border-radius: 0px !important;
}
.brand-div .css-1pahdxg-control {
  color: #0c1830 !important;
  background: #f3f4f4;
  height: 44px;
  line-height: 3;
  border: 1px solid #c7c7c7 !important;
  border-radius: 0px !important;
}
.brand-div .css-1pahdxg-control:focus {
  color: #0c1830 !important;
  height: 44px;
  box-shadow: 0 5px 10px #666;
  line-height: 3;
  border: 1px solid #c7c7c7 !important;
  border-radius: 0px !important;
}
.css-1pahdxg-control:focus {
  color: #0c1830 !important;
  height: 44px;
  line-height: 3;
  border: 1px solid #c7c7c7 !important;
  border-radius: 0px !important;
}
.brand-div .css-1d8n9bt {
  height: 40px !important;
  padding: 0px 0px 0px 37px;
  margin-top: -5px;
}
.vehicle-brand-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #0c1830 !important;
  border: 2px solid #c7c7c7;
  height: 44px;
  border-radius: 5px;
  padding: 0.375rem 0.25rem 0.375rem 0.75rem;
  position: relative;
}
.vehicle-brand-div i {
  margin-block: auto;
  font-size: 12px;
}
.open-brand {
  border: 2px solid #8fe139 !important;
}
.value-not-available {
  color: gray;
}
.vehicle-brand-label {
  margin-bottom: 0;
  margin-block: auto;
}
.search-icon-brand {
  position: absolute;
  margin: -30px 0px 0px 15px;
  color: gray !important;
}

@media only screen and (min-width: 240px) and (max-width: 479px) {
  .vehicle-container {
    width: 90%;
  }
  .spec-title-div label{
    font-size: 14px;
  }
  .vehicle-brand-label {
    font-size: 13px;
  }
  .brand-div{
    font-size: 13px;
  }
  .tos-title {
    margin-block: 6%;
    font-size: 22px;
    line-height: unset;
    margin: 15px 0px 15px 0px;
  }
  .vehicle-container input {
    font-size: 13px;
  }
  .vehicle-container select {
    font-size: 13px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 776px) {
  .vehicle-container {
    width: 70%;
  }
  .tos-title {
    font-size: 26px;
    line-height: 35px;
  }
}

@media only screen and (min-width: 777px) and (max-width: 1149px) {
  .vehicle-container {
    width: 60%;
  }
}
