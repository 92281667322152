.home-main-div {
  background: #e5e5e5;
}
.home-label {
  font-weight: 600;
  font-size: 18px;
  line-height: 24.59px;
  margin-bottom: 5%;
}
.list-home-div img {
  margin-inline-end: 5%;
  margin-top: -3px;
}
.list-home-div label {
  font-weight: 600;
  font-size: 18px;
}
.thank-you-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 49.18px;
  text-align: center;
  margin: 2% 0% 4% 0%;
}
.thank-you {
  text-align: center;
  margin-bottom: 40px;
}
.thank-you img {
  position: relative;
  width: 200px;
}
.home-button-div {
  padding-bottom: 100px;
  text-align: center;
  display: flex;
  /* right: 0;
  bottom: -35px;
  left: 0; */
  /* position: absolute; */
  justify-content: center;
  /* z-index: 2; */
}
.home-button-div button {
  background: #8fe139;
  color: white;
  border: none;
  border-radius: 30px;
  width: 315px;
  height: 40px;
  font-weight: 600;
  transition: all 0.5s ease-out 0s;
}
.home-button-div button:hover {
  color: black;
  cursor: pointer;
  transition: all 0.5s ease-out 0s;
}
.home-button-div button:focus {
  outline: none !important;
  outline: 0;
}
.home-container {
  height: 120vh !important;
}
@media only screen and (min-width: 240px) and (max-width: 479px) {
  .list-home-div img {
    margin-top: 6px;
    height: fit-content;
  }
  .list-home-div{
    display: flex;
  }
}
