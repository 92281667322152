.main-body-div {
  width: 80%;
  margin: 0 auto;
}
.thank-you-body-div {
  width: 70%;
  margin: 0 auto;
}

.previous-button {
  background: #c4c4c4 !important;
  color: white !important;
  font-size: 17px;
}
.previous-button:hover {
  color: black !important;
}
.exam-title-countdown-div {
  display: flex;
  justify-content: space-between;
}
.change-question-next-button {
  display: flex;
  justify-content: center;
  width: 40%;
  margin: auto;
  margin-block-end: 3%;
}
.change-question-next-button button {
  background: #7be04c;
  border: none;
  padding: 10px 30px;
  border-radius: 30px;
  font-weight: 500;
  font-size: 17px;
  color: white;
  width: 180px;
}
.change-question-next-button button:hover {
  color: black;
  cursor: pointer !important;
}
.change-question-next-button button:focus {
  outline: none !important;
  outline: 0;
  transition: all 0.5s ease-out 0s;
  color: #000000;
}
.exam-title-countdown-div label {
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 0rem;
  margin-block: 5%;
}
.countdown-div {
  margin-block: auto;
  font-size: 24px;
  font-weight: 600;
}
.countdown-div p {
  margin-bottom: 0rem;
}
.thank-you-banner {
  width: 100%;
  height: 400px;
  background-color: #181d33;
  background-image: url("../../../assets/banners/ThankYouBannerExam.png");
  background-size: 100% 100%, cover;
  text-align: center;
  padding-inline-end: 15%;
  padding-inline-start: 15%;
}
.thank-you-div label {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 0rem;
  margin-block: 3%;
}
.congratulations-label {
  color: #8fe039;
  font-weight: 500;
  font-size: 17px;
  line-height: 38px;
  margin-bottom: 0rem;
  margin-block: 3%;
}
.exam-submitted-label {
  color: white;
  font-weight: 500;
  font-size: 17px;
  line-height: 38px;
  margin-bottom: 0rem;
  margin-block: 3%;
  margin-block-start: 10% !important;
}
.clever-button-div {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.clever-button-div button {
  background: #8fe039;
  border: none;
  padding: 10px 30px;
  border-radius: 30px;
  font-weight: 600;
  margin: 5%;
  font-size: 17px;
  width: 180px;
  color: white;
}
.clever-button-div button:hover {
  color: black;
  cursor: pointer !important;
}
.clever-button-div button:focus {
  outline: none !important;
  outline: 0;
  transition: all 0.5s ease-out 0s;
  color: #000000;
}
.questions-div {
  background: #f3f4f4;
  border: 1px solid #d7dae3;
}
.questions-header {
  width: 100%;
  height: fit-content;
  background: #d7dae3;
  font-size: 14px;
}
.questions-header label {
  margin-bottom: 0rem;
  margin: 1% 0% 1% 5%;
  font-weight: 600;
  font-size: 17px;
}
.change-question-div {
  display: flex;
  justify-content: space-between;
  width: 40%;
  margin: auto;
  margin-block-end: 3%;
}
.change-question-div button {
  background: #7be04c;
  border: none;
  padding: 10px 30px;
  border-radius: 30px;
  font-weight: 500;
  font-size: 17px;
  color: white;
  width: 180px;
}
.change-question-div button:hover {
  color: black;
  cursor: pointer !important;
}
.change-question-div button:focus {
  outline: none !important;
  outline: 0;
  transition: all 0.5s ease-out 0s;
  color: #000000;
}
.submit-answer-button {
  background: #7be04c;
  border: none;
  padding: 10px 30px;
  border-radius: 30px;
  font-weight: 600;
  font-size: 17px;
  color: white;
  float: right;
  margin: 5% 0 5% 0;
  width: 180px;
}
.submit-answer-button:focus {
  outline: none !important;
  outline: 0;
  transition: all 0.5s ease-out 0s;
  color: #000000;
}
.submit-answer-button:hover {
  color: black;
  cursor: pointer !important;
}
.answer-div label {
  margin-bottom: 0rem;
  font-size: 17px;
  margin-left: 15px;
}
.question-label-div {
  width: 90%;
  margin: 0 auto;
  margin-block: 1.5%;
}
.question-label-div hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #344058;
}
.answer-div {
  display: flex;
}
.answer-div input[type="checkbox"] {
  background: #c4c4c4;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  border: none;
  appearance: unset;
  vertical-align: unset;
  -webkit-appearance: initial !important;
}
.answer-div input[type="checkbox"]:checked {
  background: #8fe039;
}
.answer-div input[type="checkbox"]:hover {
  cursor: pointer;
}

@media only screen and (min-width: 240px) and (max-width: 479px) {
  .clever-button-div button {
    font-size: 12px;
    width: auto;
  }
  .countdown-div p {
    font-size: 16px;
  }
  .questions-header label {
    font-size: 16px;
  }
  .change-question-div {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    margin-block: 8%;
  }
  .change-question-div button {
    background: #7be04c;
    border: none;
    padding: 10px 25px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 12px !important;
    color: white;
    width: 100px;
  }
  .submit-answer-button {
    background: #7be04c;
    border: none;
    padding: 10px 25px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 12px !important;
    color: white;
    float: right;
    width: auto;
    margin: 5% 0 5% 0;
  }
  .exam-title-countdown-div label {
    font-weight: 600;
    font-size: 15px;
    line-height: 38px;
    margin-bottom: 0rem;
    margin-block: 5%;
  }
  .change-question-next-button button {
    background: #7be04c;
    border: none;
    padding: 10px 25px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 12px !important;
    color: white;
  }
  .countdown-div {
    margin-block: auto;
    font-size: 15px;
    font-weight: 600;
  }
  .question-label-div label {
    margin-bottom: 0rem;
    font-size: 14px;
    margin-block: auto;
    padding-block-start: 2%;
  }
  .answer-div {
    max-width: -moz-fit-content;
    max-width: fit-content;
    height: 24px;
    margin-bottom: 1rem;
  }
  .change-question-next-button {
    display: flex;
    justify-content: center;
    width: 40%;
    margin: auto;
    margin-block: 6%;
  }
  .thank-you-banner {
    padding-inline-end: 5%;
    padding-inline-start: 5%;
    height: 250px;
  }
  .thank-you-div label {
    font-size: 14px;
    line-height: 2;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .change-question-div {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    margin-block-end: 5%;
  }
  .thank-you-banner {
    padding-inline-end: 5%;
    padding-inline-start: 5%;
    height: 300px;
  }
  .thank-you-div label {
    font-size: 16px;
    line-height: 2;
  }
}
