.tos-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 49.18px;
  margin: 2% 0% 4% 0%;
}
.tos-label {
  font-weight: 600;
  font-size: 18px;
  line-height: 24.59px;
}
.tos-banner-div {
  width: 100%;
  text-align: center;
  margin-top: 18%;
}
.tos-div select {
  color: gray;
  width: 100%;
  margin-bottom: 7%;
}
.css-1s2u09g-control {
  /* font-size: 14px !important; */
  color: #0c1830 !important;
  height: 44px !important;
  border-radius: 5px !important;
  border: 2px solid #c7c7c7 !important;
}
.css-1pahdxg-control:hover {
  height: 44px !important;
  border-radius: 5px !important;
  border-color: unset !important;
  border-width: 0 !important;
  border: 2px solid #8fe139 !important;
  box-shadow: 0 0 0 0 white !important;
}
.css-1pahdxg-control {
  height: 44px !important;
  border-radius: 5px !important;
  border-color: unset !important;
  border-width: 0 !important;
  border: 2px solid #8fe139 !important;
  box-shadow: 0 0 0 0 white !important;
}
.css-1pahdxg-control:focus {
  height: 44px !important;
  border-radius: 5px !important;
  border-color: unset !important;
  border-width: 0 !important;
  border: 2px solid #8fe139 !important;
  box-shadow: 0 0 0 0 white !important;
}
.css-319lph-ValueContainer {
  height: 40px !important;
}
.css-g1d714-ValueContainer{
  flex-wrap: nowrap !important;
}
.css-1hwfws3 {
  flex-wrap: nowrap !important;
}
.input-focus input:focus {
  border: unset !important;
  color: black;
}
.input-focus input {
  height: 23px !important;
  color: black;
}
.tos-button-div {
  margin-block: 40px;
  text-align: center;
}
.tos-button-div button {
  background: #8fe139;
  color: white;
  border: none;
  border-radius: 30px;
  width: 315px;
  height: 40px;
  font-weight: 600;
  transition: all 0.5s ease-out 0s;
}
.tos-button-div button:hover {
  color: black;
  cursor: pointer;
  transition: all 0.5s ease-out 0s;
}
.tos-button-div button:focus {
  outline: none !important;
  outline: 0;
}
@media only screen and (min-width: 240px) and (max-width: 479px) {
  .tos-label {
    font-size: 16px;
  }
  .tos-button-div button {
    width: 200px;
  }
  .tos-div {
    font-size: 13px;
  }
  .tos-image-width {
    width: 280px;
  }
  .css-1hb7zxy-IndicatorsContainer {
    position: sticky !important;
    max-height: 41px !important;
    top: 0 !important;
    bottom: 0 !important;
  }
  .css-1wy0on6 {
    position: sticky !important;
    max-height: 41px !important;
    top: 0 !important;
    bottom: 0 !important;
  }
  .css-1s2u09g-control {
    overflow: auto;
  }
  .css-1pahdxg-control {
    overflow: auto !important;
    font-size: 14px !important;
  }
  .css-1s2u09g-control {
    font-size: 13px !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 776px) {
  .css-1hb7zxy-IndicatorsContainer {
    position: sticky !important;
    max-height: 41px !important;
    top: 0 !important;
    bottom: 0 !important;
  }
  .css-1wy0on6 {
    position: sticky !important;
    max-height: 41px !important;
    top: 0 !important;
    bottom: 0 !important;
  }
  .css-1pahdxg-control {
    overflow: auto !important;
    font-size: 14px !important;
  }
  .css-1s2u09g-control {
    overflow: auto !important;
  }
  .css-1s2u09g-control {
    font-size: 13px !important;
  }
}

@media only screen and (min-width: 777px) and (max-width: 1149px) {
  .input-focus {
    width: 65%;
  }

  .css-1s2u09g-control {
    font-size: 14px !important;
  }
}
