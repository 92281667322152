.verify-otp-label {
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  color: #0c1830;
  text-align: center;
}
.otp-input-div input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.otp-field {
  justify-content: space-between;
  display: flex;
}
.otp-field input {
  text-align: center;
}
.otp-lbl-div {
  display: flex;
  justify-content: center;
  margin-block: 5%;
  font-size: 18px;
  line-height: 20px;
}
.resend-div {
  text-align: center;
  margin: 10% 0% 0% 0%;
}
.otp-container {
  width: 43%;
  background: white;
  margin: auto;
  padding: 2% 2% 0 2%;
  height: 100vh;
}
.otp-container input {
  color: black;
  border-radius: 5px;
  width: 50px !important;
  height: 50px !important;
  border: 2px solid #c7c7c7;
  width: 100%;
}
.otp-container input:focus {
  border-color: unset !important;
  outline: 0;
  color: black;
  box-shadow: 0 0 0 0 !important;
  border: 2px solid #8fe139 !important;
}
.otp-input-div {
  justify-content: center;
  display: grid;
}
.verify-button-div {
  margin-block: 5%;
  text-align: center;
}
.verify-button-div button {
  background: #8fe139;
  color: white;
  border: none;
  border-radius: 30px;
  width: 315px;
  height: 40px;
  font-weight: 600;
  transition: all 0.5s ease-out 0s;
}
.verify-button-div button:hover {
  color: black;
  cursor: pointer;
  transition: all 0.5s ease-out 0s;
}
.verify-button-div button:focus {
  outline: none !important;
  outline: 0;
}
.disable-btn button {
  background: #cccccc !important;
  pointer-events: none;
}
@media only screen and (min-width: 240px) and (max-width: 479px) {
  .otp-container {
    width: 90%;
  }
  .resend-div {
    font-size: 14px;
  }
  .resend-label {
    font-size: 14px;
  }
  .verify-otp-label {
    font-size: 22px;
    line-height: normal;
    margin-top: 10px;
  }
  .otp-lbl-div {
    display: block;
    text-align: center;
  }
  .otp-lbl-div label{
    font-size: 14px;
  }
  .otp-container input {
    width: 35px !important;
    height: 35px !important;
  }
  .verify-button-div button {
    width: 200px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 776px) {
  .otp-container {
    width: 70%;
  }
  .verify-otp-label {
    font-size: 31px;
    line-height: normal;
    margin-top: 10px;
  }
  .otp-container input {
    width: 40px !important;
    height: 40px !important;
  }
  .otp-lbl-div {
    display: block;
    text-align: center;
  }
}

@media only screen and (min-width: 777px) and (max-width: 1065px) {
  .otp-container {
    width: 70%;
  }
}
