.header-partner-div {
  background: #141a30;
  text-align: start;
  padding-inline-start: 7%;
}
.header-partner-div img {
  width: 120px;
  margin-block: 1%;
}
@media only screen and (min-width: 240px) and (max-width: 479px) {
  .header-partner-div {
    text-align: center !important;
    padding-block: 10px !important;
    padding-inline-start: 0 !important;
  }
}
