.header-div {
  justify-content: space-between;
  width: 100%;
  background-color: #0c1830;
  display: flex;
  height: 95px;
  align-items: center;
}

.header-div button {
  background: #8fe139;
  color: white;
  border: none;
  line-height: 1;
  padding: 10px 30px 10px 30px;
  border-radius: 30px;
  margin-bottom: 20px;
  font-weight: 600;
  margin-block: auto;
  width: 180px;
  font-size: 17px;
}

.header-div button:hover {
  color: black;
  cursor: pointer !important;
}

.header-logo {
  margin: 0 0 0 5%;
  width: 120px;
}

.header-btn-div {
  margin: 0 5% 0 0;
}

@media only screen and (min-width: 240px) and (max-width: 500px) {
  .header-div {
    height: 75px !important;
  }
  .header-div button {
    padding: 10px 20px 10px 20px;
    font-size: 14px;
    width: auto;
  }
  .header-logo {
    width: 130px;
    object-fit: contain;
    padding: 20px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .header-div button {
    width: auto;
  }
  .header-logo {
    width: 160px;
    object-fit: contain;
    padding: 20px;
  }
}
