.main-menu-container {
  background: #e5e5e5;
  padding: 0 10%;
}
.menu-container {
  background: #f3f4f4;
  width: 20%;
  padding-bottom: 40%;
  box-shadow: 10px 0 8px -8px rgb(0 0 0 / 25%);
}
.menu-label-div {
  display: grid;
  padding-left: 15%;
}
.menu-label-div label {
  margin-block: 15%;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
}
.menu-label-div label:hover {
  color: #8fe039;
  font-size: 22px;
  cursor: pointer;
}
.layout-container {
  display: flex;
  background: white;
}
.section-selected {
  color: #8fe039;
}

@media only screen and (min-width: 240px) and (max-width: 479px) {
  .profile-label {
    font-size: 22px !important;
  }
  .main-menu-container {
    padding: 0 4%;
  }
  .layout-container {
    display: block;
  }
  .menu-container {
    width: 100%;
    padding-bottom: 00%;
    box-shadow: 0px 0 0px 0px rgb(0 0 0 / 25%);
  }
  .menu-label-div {
    width: auto;
    display: flex;
    overflow-x: auto;
    height: auto;
    overflow-wrap: anywhere;
    padding-left: 0;
  }
  .menu-label-div label {
    font-size: 16px;
    flex-shrink: 0;
    width: auto;
    margin: 7% 25px;
  }
  .menu-label-div label:hover {
    font-size: 16px;
  }
  .profile-container {
    width: 95%;
    margin: 0 auto;
    margin-top: 15px;
  }
  .document-main-div {
    width: 95%;
    margin: 0 auto;
    margin-top: 15px;
    padding: 0 5px;
  }
  .document-hr {
    margin-bottom: 5%;
    margin-top: 5px;
  }
  .ride-hr {
    margin-bottom: 5%;
    margin-top: 5px;
  }
  .profile-div hr {
    margin-top: 5px;
  }
  .document-section label {
    padding: 3% 5%;
    font-size: 15px;
    font-weight: 600;
  }
  .document-section i {
    margin-right: 0px;
  }
  .profile-input-div label {
    font-size: 14px;
  }
  .profile-input-div input {
    font-size: 15px;
  }
  .profile-image-div label {
    font-size: 14px;
  }
  .vehicle-title-disabled {
    font-size: 15px;
  }
  .approved-div {
    margin-top: 0px;
  }
  .approved-div span {
    font-size: 13px;
  }
  .list-label label {
    font-size: 13px !important;
    margin-top: 8px;
  }
  .list-label li {
    font-size: 13px !important;
  }
  .upload-btn-span {
    display: block;
  }
  .file-name {
    margin: 0;
  }
  .file-name img {
    margin-bottom: 5px;
  }
}
.modal {
  z-index: 700 !important;
}
.modal-backdrop {
  z-index: 500 !important;
}
@media only screen and (min-width: 480px) and (max-width: 776px) {
  .main-menu-container {
    padding: 0 4%;
  }
  .layout-container {
    display: block;
  }
  .menu-container {
    width: 100%;
    padding-bottom: 0%;
    box-shadow: 0px 0 0px 0px rgb(0 0 0 / 25%);
  }
  .menu-label-div {
    width: auto;
    display: flex;
    overflow-x: auto;
    height: auto;
    overflow-wrap: anywhere;
    padding-left: 0;
  }
  .menu-label-div label {
    font-size: 16px;
    flex-shrink: 0;
    width: auto;
    margin: 7% 25px;
  }
  .menu-label-div label:hover {
    font-size: 16px;
  }
  .profile-container {
    width: 95%;
    margin: 0 auto;
    margin-top: 15px;
  }
  .profile-label {
    font-size: 25px !important;
  }
  .document-main-div {
    width: 95%;
    margin: 0 auto;
    margin-top: 15px;
    padding: 0 5px;
  }
  .document-hr {
    margin-bottom: 5%;
    margin-top: 5px;
  }
  .ride-hr {
    margin-bottom: 5%;
    margin-top: 5px;
  }
  .profile-div hr {
    margin-top: 5px;
  }
  .document-section label {
    padding: 3% 5%;
    font-size: 15px;
    font-weight: 600;
  }
  .document-section i {
    margin-right: 0px;
  }
  .profile-input-div label {
    font-size: 15px;
  }
  .profile-input-div input {
    font-size: 15px;
  }
  .profile-image-div label {
    font-size: 15px;
  }
  .vehicle-title-disabled {
    font-size: 15px;
  }
  .approved-div {
    margin-top: 0px;
  }
  .approved-div span {
    font-size: 13px;
  }
  .list-label label {
    font-size: 13px !important;
    margin-top: 8px;
  }
  .list-label li {
    font-size: 13px !important;
  }
  .upload-btn-span {
    display: block;
  }
  .file-name {
    margin: 0;
  }
  .file-name img {
    margin-bottom: 5px;
  }
}

@media only screen and (min-width: 777px) and (max-width: 1065px) {
}
