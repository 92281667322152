@font-face {
  font-family: "Avenir Next";
  src: url(https://pre-api.vooyapp.com/default_attachments/font/AvenirNext-Regular.ttf);
}
select {
  padding: 0.375rem 0.4rem !important;
}
.rejected-remark {
  color: red;
  font-weight: 600;
}
.login-main-div {
  background: #e5e5e5;
}
.value-available select {
  color: black !important;
  appearance: none;
}
option[value="-1"][disabled] {
  display: none;
}
.input-div {
  display: flex;
}
.login-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 49.18px;
  margin: 2% 0% 4% 0%;
}
.pass-div {
  position: relative;
}
.pass-div input {
  position: relative;
  color: black;
}
.pass-div i {
  position: absolute;
  padding: 10px;
  top: 4px;
  right: 15px;
  color: #796d70;
}
.pass-div i:hover {
  cursor: pointer;
}
.login-container {
  width: 43%;
  background: white;
  margin: auto;
  height: 100vh;
  padding: 2% 2% 0 2%;
}
.login-container input {
  color: #0c1830 !important;
  height: 44px;
  border: 2px solid #c7c7c7;
  border-radius: 5px;
  color: black;
}
.login-container input:focus {
  color: black;
  border-color: unset !important;
  outline: 0;
  box-shadow: 0 0 0 0 !important;
  border: 2px solid #8fe139 !important;
}
.login-container select:focus {
  border-color: unset !important;
  outline: 0;
  box-shadow: 0 0 0 0 !important;
  border: 2px solid #8fe139 !important;
  color: black;
}
.login-container select {
  border: 2px solid #c7c7c7;
  border-radius: 5px;
  height: 44px;
  width: auto;
  margin-inline-end: 2%;
  color: gray;
}
.mobileNo-div:focus-within label {
  color: #8fe139 !important;
}
.email-div:focus-within label {
  color: #8fe139 !important;
}
.pass-div:focus-within label {
  color: #8fe139 !important;
}
.change-login-option label {
  color: #8fe139;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0;
}
.change-login-option label:hover {
  cursor: pointer;
  text-decoration: underline;
}
.login-button-div {
  margin-block: 30px;
  text-align: center;
}
.login-button-div button {
  background: #8fe139;
  color: white;
  border: none;
  border-radius: 30px;
  width: 315px;
  height: 40px;
  font-weight: 600;
  transition: all 0.5s ease-out 0s;
}
.login-button-div button:hover {
  color: black;
  cursor: pointer;
  transition: all 0.5s ease-out 0s;
}
.login-button-div button:focus {
  outline: none !important;
  outline: 0;
}
.signup-div {
  text-align: center;
}
.signup-label {
  color: #8fe139;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}
.signup-label:hover {
  cursor: pointer;
  text-decoration: underline;
}
.language-div {
  display: flex;
  margin-block: 5%;
}
.language-div i {
  margin-block: auto;
  font-size: 22px;
}
.language-div select {
  margin-left: 10px;
  color: gray;
}
.disable-btn button {
  background: #cccccc !important;
  pointer-events: none;
}
.mobile-no-span {
  width: 100%;
}
.form-group {
  margin-bottom: 1.5rem !important;
}
::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: black;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: black;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: black;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black;
}
::placeholder {
  /* Most modern browsers */
  color: black;
}
.forgot-pass-div {
  text-align: center;
  margin: 15px 0px 35px 0px;
}
.forgot-pass-div a {
  color: #8fe139;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
}
.forgot-pass-div a:hover {
  color: #8fe139;
  cursor: pointer;
  text-decoration: underline;
}
@media only screen and (min-width: 240px) and (max-width: 479px) {
  .forgot-pass-div a {
    font-size: 14px;
  }
  .signup-div label {
    font-size: 14px;
  }
  .change-login-option label {
    font-size: 14px;
  }
  .login-link-div {
    font-size: 14px;
  }
  .login-link-div span b {
    font-size: 14px;
  }
  .login-container {
    width: 90%;
  }
  .login-title {
    font-size: 22px;
    line-height: 35px;
  }
  .login-button-div button {
    width: 200px;
  }
  .login-button-div {
    margin-block: 25px;
  }
  .language-div {
    margin-block: 7%;
    justify-content: center;
  }
}

@media only screen and (min-width: 480px) and (max-width: 776px) {
  .login-container {
    width: 70%;
  }
  .login-title {
    font-size: 26px;
    line-height: 35px;
  }
  .login-button-div button {
    width: 200px;
  }
  .login-button-div {
    margin-block: 25px;
  }
  .language-div {
    margin-block: 7%;
    justify-content: center;
  }
}

@media only screen and (min-width: 777px) and (max-width: 1065px) {
  .login-button-div button {
    width: 200px;
  }
  .login-button-div {
    margin-block: 25px;
  }
  .language-div {
    margin-block: 7%;
    justify-content: center;
  }
  .personal-container {
    width: 70%;
  }
}
