.modals .alert-icon {
    display: flex;
    justify-content: center;
}

.modals .alert-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.modals .alert-content p {
    text-align: center;
    margin-top: 15px;
}

.modals .buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
}



.trail-modal .trial-desc {
    display: flex;
    justify-content: space-around;
}

.trail-modal .trial-desc label {
    font-weight: 600;
}

.trail-modal h1 {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 15px;
}