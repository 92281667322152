@font-face {
  font-family: "Avenir Next";
  src: url(https://pre-api.vooyapp.com/default_attachments/font/AvenirNext-Regular.ttf);
}

body {
  font-family: "Avenir Next" !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
