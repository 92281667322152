.rating-attachment-div {
  text-align: center;
}
.modal {
  margin-top: 100px !important;
}
.modal-body {
  padding: 4% 7% !important;
}
.document-container {
  margin: auto;
  width: 43%;
  background: white;
  /* padding: 2% 2% 0 2%; */
}
.document-container-upload {
  margin: auto;
  width: 43%;
  background: white;
  padding: 2% 2% 0 2%;
  height: 100vh;
}
.vehicle-ssn-container {
  margin: auto;
  width: 43%;
  background: white;
  padding: 2% 2% 0 2%;
  height: 100vh;
}

.rating-attachment-div input {
  display: none;
  color: black;
}
.rating-attachment-div img {
  padding: 5px 0px 10px 10px;
}
.upload-rating-label {
  display: inline-block !important;
  cursor: pointer !important;
  text-decoration: underline;
  font-size: 15px;
  color: #8fe139;
  text-decoration: none;
  border: 2px dashed #8fe139;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5px 20px 2px 20px;
  white-space: nowrap;
  width: fit-content;
}
.upload-rating-label:hover {
  cursor: pointer;
}
.status-div {
  display: inline-block;
}
.rejected-remark-doc {
  color: red;
  font-weight: 600;
}
.main-status-div {
  display: flex;
  justify-content: space-around;
  padding: 4% 4% 0 4%;
}
.doc-tab-div {
  padding: 0% 5% 0 5%;
}
.status-div img {
  margin-inline-end: 10px;
}
.tax-info-section {
  margin-bottom: 5%;
}
.tax-info-section label {
  margin-bottom: 0;
  border: 1px solid darkgrey;
  border-radius: 5px;
  width: 100%;
  padding: 1.5% 1.5% 1.5% 3.5%;
  font-size: 18px;
  font-weight: 600;
}
.tax-info-section label:hover {
  cursor: pointer;
}
.tax-info-section i {
  float: right;
  line-height: unset;
}
.margin-b {
  margin-bottom: 5%;
}
.hr-horizontal {
  margin-bottom: 6%;
  margin-top: 3%;
}
.personal-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 49.18px;
  margin: -2% 0% 4% 0%;
}
.vehicle-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 49.18px;
  margin: 0% 0% 5% 0%;
}
.curp-div a {
  background: #e2e2e2;
  border-radius: 30px;
  color: black;
  text-decoration: none;
  padding: 7px 15px 7px 15px;
  margin-bottom: 4%;
}
.curp-div a:hover {
  cursor: pointer;
  color: white;
  background: #8fe139;
}
.curp-div label {
  font-size: 18px;
  font-weight: 500;

  margin-bottom: 6%;
}
.curp-input-div {
  margin-top: 30px;
}
.rejected label {
  border: 2px solid #e0393b !important;
  background: rgba(224, 57, 59, 0.3);
}
.approved label {
  border: 2px solid #8fe139 !important;
  background: rgba(143, 225, 57, 0.3);
}
.waiting label {
  border: 2px solid #e09b39 !important;
  background: rgba(224, 155, 57, 0.3);
}
.expiring-soon-tab label {
  border: 2px solid #21adff !important;
  background: #98d6fa;
}
.ine-div label {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 4%;
}
.document-image-div {
  text-align: center;
  margin-block: 5%;
}
.document-image-div img {
  width: 180px;
  height: 110px;
}
.document-image {
  text-align: center;
  margin-block: 5%;
}
.vehicle-image {
  text-align: center;
  margin-block: 5%;
}
.btn-m {
  margin-top: -3%;
  margin-bottom: 80px;
}
.btn-veh-m button {
  margin-top: 20px;
  margin-bottom: 50px;
}
.logout-btn-div {
  display: flex;
  justify-content: space-between;
  margin-top: 8%;
}
.logout-btn-div button {
  width: 200px !important;
  margin-bottom: 40px;
}
.return-btn {
  text-align: center;
  padding-bottom: 100px;
}
.return-btn button {
  background: white;
  color: #c7c7c7;
  border: 2px solid #c7c7c7;
  border-radius: 30px;
  width: 315px;
  height: 40px;
  font-weight: 600;
  transition: all 0.5s ease-out 0s;
}
.return-btn button:hover {
  color: black;
  cursor: pointer;
  transition: all 0.5s ease-out 0s;
}
.return-btn button:focus {
  outline: none !important;
  outline: 0;
}
.submit-btn-doc {
  padding-bottom: 25px !important;
}
.logout-button-div-doc button {
  background: white;
  color: #c7c7c7;
  border: 2px solid #c7c7c7;
  border-radius: 30px;
  width: 315px;
  height: 40px;
  font-weight: 600;
  transition: all 0.5s ease-out 0s;
}
.logout-button-div-doc button:hover {
  color: black;
  cursor: pointer;
  transition: all 0.5s ease-out 0s;
}
.logout-button-div-doc button:focus {
  outline: none !important;
  outline: 0;
}
.logout-button-div-doc {
  margin-block-end: 6%;
}
.document-image img {
  /* width: 180px; */
  height: 130px;
}
.logout-bttn-div {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}
.no-btn {
  line-height: 0;
  background: #e2e2e2;
  border: none;
  border-radius: 30px;
  width: 100px;
  height: 40px;
  font-weight: 600;
  transition: all 0.5s ease-out 0s;
}
.no-btn:hover {
  color: black;
  cursor: pointer;
  transition: all 0.5s ease-out 0s;
}
.no-btn:focus {
  outline: none !important;
  outline: 0;
}
.yes-btn {
  line-height: 0;
  background: #8fe139;
  border: none;
  border-radius: 30px;
  width: 100px;
  height: 40px;
  font-weight: 600;
  transition: all 0.5s ease-out 0s;
}
.yes-btn:hover {
  color: black;
  cursor: pointer;
  transition: all 0.5s ease-out 0s;
}
.yes-btn:focus {
  outline: none !important;
  outline: 0;
}
.logout-label {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  margin-block: 2%;
}
.logout-content-label {
  margin-top: 10px;
  margin-bottom: 30px;
}

.expiring-soon {
  border: 1px solid #21adff;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  background-color: #98d6fa;
}

.rejected-icon {
  border: 1px solid #e0393b;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  background-color: rgba(224, 57, 59, 0.3);
}

.approved-icon {
  border: 1px solid #8fe139;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  background-color: rgba(143, 225, 57, 0.3);
}

.pending-icon {
  border: 1px solid #c7c7c7;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  background-color: rgba(199, 199, 199, 0.1);
}

.waiting-icon {
  border: 1px solid #e09b39;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  background-color: rgba(224, 155, 57, 0.3);
}

.status-icon-div {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.status-icon-div label {
  margin-bottom: 0;
  margin-left: 10px;
}

.next-btn-div button {
  background: #8fe139;
  color: white;
  border: none;
  border-radius: 30px;
  width: 315px;
  height: 40px;
  font-weight: 600;
  transition: all 0.5s ease-out 0s;
}
.next-btn-div button:hover {
  color: black;
  cursor: pointer;
  transition: all 0.5s ease-out 0s;
}
.next-btn-div button:focus {
  outline: none !important;
  outline: 0;
}
.next-btn-div {
  margin-block-end: 6%;
}
.vehicle-image img {
  width: 180px;
  height: 110px;
}

@media only screen and (min-width: 240px) and (max-width: 479px) {
  .curp-div label {
    font-size: 14px;
  }
  .upload-rating-label{
    padding: 3px 13px 0px 13px;
    margin-top: 5px;
  }
  .document-container {
    width: 90%;
  }
  .document-container-upload {
    width: 90%;
  }
  .ine-div label {
    font-size: 14px;
  }
  .ine-div li {
    font-size: 14px;
  }
  .vehicle-image img {
    height: 100px;
    width: 270px;
  }
  .tax-info-section label {
    font-size: 14px;
    padding: 2% 2% 2% 4%;
  }
  .tax-info-section {
    margin-bottom: 20px;
  }
  .logout-btn-div {
    margin-top: 12%;
  }
  .personal-title {
    margin-block: 6%;
    font-size: 22px;
    line-height: unset;
    margin: 15px 0px 15px 0px;
  }
  .vehicle-title {
    margin-block: 6%;
    font-size: 22px;
    line-height: unset;
    margin: 15px 0px 15px 0px;
  }
  .status-label {
    font-size: 12px;
  }
  .logout-btn-div button {
    width: 120px !important;
  }
  .next-btn-div button {
    width: 200px;
  }
  .return-btn button {
    width: 200px;
    font-size: 14px;
  }
  .vehicle-image-doc img {
    height: 80px;
  }
  .list-home-div label {
    font-size: 13px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 776px) {
  .personal-title {
    font-size: 28px;
  }
  .vehicle-title {
    font-size: 28px;
}
  .document-container {
    width: 70%;
  }
  .document-container-upload {
    width: 70%;
  }
  .vehicle-image img {
    height: 100px;
    width: 300px;
  }
  .logout-btn-div button {
    width: 155px !important;
  }
  .vehicle-image-doc img {
    height: 80px;
  }
  .next-btn-div button {
    width: 200px;
  }
}

@media only screen and (min-width: 777px) and (max-width: 1149px) {
  .document-container {
    width: 60%;
  }
  .document-container-upload {
    width: 60%;
  }
  .vehicle-image img {
    height: 150px;
    width: 400px;
  }
}
