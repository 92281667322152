.add-card-details {
    margin-top: 2rem;
    height: 100vh;
}

.add-card-details .validity-cvv-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.add-card-details .card-detail-input label {
    font-size: 15px;
}

.add-card-details .card-detail-input input {
    font-size: 15px;
}

.add-card-details .card-detail-input {
    margin-bottom: 1.5rem;
    text-align: left;
}

.add-card-details .card-detail-input input {
    position: relative;
    height: 44px;
    border: 0px solid #ced4da;
    background: #f3f4f4;
    border-radius: 0;
}

.add-card-details .date-pick input {
    min-width: 100%;
    padding-left: 11px;
}

.add-card-details .payment-button {
    display: flex;
    justify-content: center;
}

.radio-buttons p {
    position: relative;
    top: 7px;
}

.react-datepicker-wrapper {
    display: block !important;
}

.invalid-feedback {
    display: block !important;
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;
}


.subscription-container {
    padding-top: 2px;
    width: 100%;
    background: linear-gradient(-5deg, #0c1830 73%, #f4f5f9 50%);
    min-height: 100vh;
}

.subscription-plan {
    margin-top: 70px;
}

.vooy-image {
    margin-top: 50px;
    display: flex;
    justify-content: center;
}


.subscription-plan .heading1 {
    text-align: center;
    color: white;
    font-weight: 900;
    margin-top: 128px;
    width: 40%;
    padding: 5px;
    position: relative;
    background: #8fe139;
    left: 31%;
}

.subscription-plan .heading2 {
    text-align: center;
    color: white;
    font-size: 30px;
    font-weight: 900;
    margin-top: 40px;
}

.color-white {
    color: white;
}

.subscription-plan .points {
    display: flex;
    justify-content: center;
}

.vooy-image img {
    max-width: 10%;
}

.subscription-plan .points i {
    color: white;
    margin-top: 5px;
    margin-right: 10px;
}


.subscription-plan .subscription-btn {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

@media (min-width: 1550px) and (max-width: 2200px){
    .subscription-plan .heading1 {
        margin-top: 151px;
        width: 52%;
        left: 24%;
        font-size: 32px;
    }
}

@media (min-width: 1200px) and (max-width: 1549px) {
    .subscription-plan .heading1 {
        margin-top: 115px;
        padding: 2px;
        font-size: 32px;
        left: 26%;
        width: 47%;
    }
}


@media (max-width: 1199px) {
    .subscription-plan .heading1 {
        margin-top: 80px;
        width: 52%;
        left: 24%;
        font-size: 32px;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .subscription-plan .heading1 {
        text-align: center;
        margin-top: 94px;
        width: 69%;
        left: 17%;
        font-size: 26px;
    }
}

@media (max-width: 768px) {
    .subscription-plan .heading1 {
        width: 58%;
        left: 23%;
        margin-top: 93px;
        font-size: 24px;
    }
}


/* Card Details Start */

.card-details h1,
.card-listing h1,
.validate-details h1 {
    font-weight: 700;
    margin-top: 50px;
    text-align: center;
}

.card-details h6 {
    text-align: center;
    font-weight: 500;
    margin-top: 15px;
}

.card-details .total {
    display: flex;
    justify-content: center;
}

.card-details .total-section {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin-top: 15px;
    padding: 25px 25px 12px 25px;
    background-color: #8fe139;
    border-radius: 15px;
    width: 40%;
}

.card-details .total-section {
    color: white;
    font-weight: 600;
    font-size: 18px;
}

.card-details .detail-container {
    display: flex;
    justify-content: center;
}

.card-details .details {
    color: white;
    background-color: #0c1830;
    margin-top: 30px;
    padding: 25px;
    border-radius: 15px;
    width: 60%;
}

.card-details .details div {
    display: flex;
    justify-content: space-between;
}

/* card detail end */


/* card listing start */

.card-listing .cards {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 15px;
}

.card-listing .cards .card-box {
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 25px;
    border-radius: 15px;
    padding: 25px 15px;

}

.box-shadow {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.height-300 {
    height: 300px;

}

.height-min {
    height: min-content;
}

.card-listing .cards .card-box .no-cards {
    text-align: center;
    font-size: 14px;
    font-weight: 600
}

.card-listing .cards .card-box::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
}

.card-listing .cards .card-box::-webkit-scrollbar-thumb {
    background-color: #0c1830;
    border-radius: 10px;
}

.card-listing .cards .card-inner-box {
    display: flex;
    justify-content: center;
    gap: 70px;
    margin-top: 10px;
}

.fa-trash {
    color: rgb(209, 6, 6);
    margin-top: 19px;
    cursor: pointer;
}

.card-listing .cards .card-details {
    display: flex;
    gap: 10px;
}

.height-15 {
    height: 15px;
}

.height-20 {
    height: 20px;
}

.card-listing .cards .card-details {
    font-size: 14px;
    font-weight: 600;

}

.or-text {
    text-align: center !important;
    margin-top: 24px;
}

/* card listing end */



/* subscription validate start */

.validate-details {
    margin-top: 10pc;
}

.validate-details h1 {
    text-align: center;

}

.validate-details .validate-detail-container {
    display: flex;
    justify-content: center;
}

.validate-details .validate-detail-inner-box {
    padding: 15px;
    border-radius: 25px;
    width: 50%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.validate-details .validate-detail-inner-box span {
    padding: 5px;
}

.activeSubscription {
    background-color: #8fe139;
    color: white;
    font-weight: 500;
    border-radius: 10px;
}

.inActiveSubscription {
    background-color: red;
    color: white;
    font-weight: 500;
    border-radius: 10px;
}

.validate-details .validate-detail-inner-box div {
    display: flex;
    justify-content: space-between;
}

.validate-details .validate-detail-inner-box div label {
    font-weight: 600;
    font-size: 16px;
}

/* subscription validate end */


.nav-tabs {
    border-bottom: none !important;
}

.nav-tabs .nav-item #fill-tab-example-tab-existingCards {
    border-radius: 10px 0px 0px 10px !important;
}

.nav-tabs .nav-item #fill-tab-example-tab-addNewCard {
    border-radius: 0px 10px 10px 0px !important;
}

.nav-tabs .nav-link.active {
    color: white !important;
    background-color: #0c1830 !important;
}

.nav-tabs .nav-link {
    isolation: isolate;
    color: white !important;
    background-color: #8fe139 !important;
    font-weight: 600 !important;
    padding: 20px 10px 30px 10px !important;
    border-color: white !important;
}

.nav-tabs .nav-link:hover {
    isolation: none !important;

}