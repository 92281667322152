.service-label {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #0c1830;
}
.service-button-div {
  margin: 5% 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.service-button-div button {
  color: rgba(0, 0, 0, 0.56);
  border: none;
  font-weight: 600;
  transition: all 0.5s ease-out 0s;
  width: 70px;
  height: 35px;
  background: rgba(143, 224, 57, 0.33);
  border-radius: 5px;
}
.service-button-div button:hover {
  color: black;
  cursor: pointer;
  transition: all 0.5s ease-out 0s;
}
.service-button-div button:focus {
  outline: none !important;
  outline: 0;
}
.button-opted {
  background: #8fe039 !important;
  color: white !important;
}
.toggle-button-div {
  display: flex;
  width: 35%;
  justify-content: space-between;
}
.m-b {
  margin-block: 7%;
}
@media only screen and (min-width: 240px) and (max-width: 479px) {
  .toggle-button-div {
    width: 55%;
  }
}

@media only screen and (min-width: 480px) and (max-width: 776px) {
  .toggle-button-div {
    width: 55%;
  }
}

@media only screen and (min-width: 777px) and (max-width: 1149px) {
  .login-container {
    width: 70%;
  }
}
