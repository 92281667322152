button {
    width: 180px;
    height: 40px;
    font-weight: 600;
    transition: all 0.5s ease-out 0s;
    line-height: normal;
    border: none;
    outline: none;
}

.primaryButton {
    background: #8fe139;
    color: #fff;
    border-radius: 30px;
}

.primaryButton:hover {
    color: #000;
}

.secondaryButton {
    background: #fff;
    color: #8fe139;
    border-radius: 4px;
}

.secondaryButton:hover {
    color: #000;
}

.dangerButton {
    background: red;
    color: white;
    border-radius: 30px;
}

.dangerButton:hover {
    color: #000;
}

.gray {
    background: #C7C7C7;
    color: white;
    border-radius: 30px;
}

.gray:hover {
    color: #000;
}