.ride-hr {
  margin-bottom: 2%;
}
.ride-shipment-hr{
  margin-bottom: 5%;
  margin-top: 5px;
}
.ride-main-div {
  height: 100vh;
  width: 70%;
  margin: 0 auto;
  margin-top: 30px;
  padding: 0 5px;
}
.shipment-div td {
  text-align: left;
  font-weight: 600;
  width: 20%;
  font-size: 14px;
}
.shipment-div table {
  border-collapse: collapse;
  width: 100%;
}
.shipment-div tr {
  height: 40px;
  padding-inline-start: 5px;
}
.menu-column-row td {
  font-size: 18px !important;
  line-height: 1;
  margin-top: auto;
  margin-bottom: auto;
}
.menu-column-row {
  display: flex;
}
.menu-column-row-odd {
  background-color: #f3f4f4;
}
.menu-column-row-odd td {
  font-size: 18px !important;
  line-height: 1;
  margin-top: auto;
  margin-bottom: auto;
}
.menu-column-row-odd {
  display: flex;
}
.scrollbar-width {
  margin-right: 12px;
}
.shipment-div table div::-webkit-scrollbar {
  -webkit-appearance: none;
}
.shipment-div table div::-webkit-scrollbar:vertical {
  width: 12px;
}
.shipment-div table div::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}
.shipment-div table div::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}
.no-records{
  font-size: 20px;
}
.M-ui-card {
  border: 0 solid #ddd!important;
  box-shadow: none!important;
}
@media only screen and (min-width: 240px) and (max-width: 479px) {
  .ride-main-div {
    height: 100vh;
    width: 95%;
    margin: 0 auto;
    margin-top: 15px;
    padding: 0 5px;
  }
  .shipment-div table div::-webkit-scrollbar:vertical {
    width: 5px;
  }
  .shipment-div {
    font-size: 10px !important;
  }
  .menu-column-row-odd td {
    font-size: 10px !important;
  }
  .menu-column-row td {
    font-size: 10px !important;
  }
  .no-records{
    font-size: 14px;
  }
  .ride-shipment-hr{
    margin-bottom: 2%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 776px) {
  .ride-main-div {
    height: 100vh;
    width: 95%;
    margin: 0 auto;
    margin-top: 15px;
    padding: 0 5px;
  }
  .no-records{
    font-size: 14px;
  }
  .menu-column-row-odd td {
    font-size: 15px !important;
  }
  .menu-column-row td {
    font-size: 15px !important;
  }
  .ride-shipment-hr{
    margin-bottom: 2%;
  }
}
