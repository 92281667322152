.home-sub-header-div {
  width: 100%;
  height: 576px;
  background: url("../../../assets/exam-images/HomeSubHeader2.png");
  display: block;
  align-items: center;
  background-size: 100% 100%, cover;
}
.home-sub-header-div label {
  font-weight: 600;
  font-size: 40px;
  color: white;
  margin-bottom: 0;
}
.sub-header-labels {
  display: grid;
  float: right;
  text-align: center;
  margin-top: 5%;
  margin-inline-end: 15%;
}
.sub-header-labels img {
  width: 70%;
  margin: auto;
}
.mission-main-div {
  display: block;
  justify-content: space-evenly;
  padding: 3%;
}
.about-mission-div {
  background: #e4edf2;
  padding: 2%;
  margin: 1%;
  width: -webkit-fill-available;
  text-align: center;
}
.about-mission-div label {
  font-weight: 600;
  font-size: 40px;
}
.about-mission-div p {
  text-align: start;
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 0.3rem;
}
.about-mission-div i {
  font-size: 15px;
  margin: 0% 3% 0% 0%;
}
.mission-vision-objective-div {
  display: flex;
}
.footer-div {
  width: 100%;
  height: 500px;
  background: url("../../../assets/exam-images/Footer.png");
  display: grid;
  align-items: center;
  background-size: 100% 100%, cover;
  text-align: center;
}
.footer-div label {
  font-size: 40px;
  color: white;
  font-weight: 600;
}
.footer-div p {
  font-size: 17px;
  color: white;
  margin-bottom: 0;
  margin-block: 3%;
}
.service-label-color {
  color: #8fe139 !important;
}
.footer-div hr {
  border-top: 2px solid #8fe139;
  width: 50%;
  margin: 0 auto !important;
}
.footer-line-div hr {
  border-top: 25px solid #8fe139;
  opacity: 1 !important;
  margin-bottom: 0 !important;
}
@media only screen and (min-width: 240px) and (max-width: 479px) {
  .mission-main-div {
    display: block;
  }
  .about-mission-div i {
    margin: 0% 3% 0% 0%;
  }
  .about-mission-div label {
    font-size: 22px;
  }
  .about-mission-div p {
    font-size: 14px;
  }
  .rides-div p {
    font-size: 14px;
  }
  .delivery-div p {
    font-size: 14px;
  }
  .start-button-div {
    margin: 5%;
}
  .mission-vision-objective-div {
    display: block;
  }
  .about-mission-div {
    padding: 5%;
    margin: 0;
    margin-top: 5%;
  }
  .home-sub-header-div {
    height: 220px;
  }
  .home-sub-header-div label {
    font-size: 20px;
    margin-bottom: 0;
  }
  .sub-header-labels img {
    width: 60%;
    margin: auto;
  }
  .sub-header-labels {
    margin-inline-end: 0%;
    margin-top: 5%;
    width: 50%;
  }
  .footer-div hr {
    width: 90%;
  }
  .footer-div label {
    font-size: 32px;
  }
  .footer-div p {
    font-size: 14px;
    margin-block: 5%;
    padding: 0 30px;
  }
  .footer-div {
    height: 300px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .mission-main-div {
    display: block;
  }
  .about-mission-div i {
    margin: 0% 3% 0% 0%;
  }
  .about-mission-div {
    width: 100%;
  }
  .mission-vision-objective-div {
    display: block;
  }
  .about-mission-div {
    padding: 5%;
    margin: 0;
    margin-top: 5%;
  }
  .home-sub-header-div {
    height: 300px;
  }
  .home-sub-header-div label {
    font-size: 30px;
    margin-bottom: 0;
  }
  .sub-header-labels img {
    width: 50%;
    margin: auto;
  }
  .sub-header-labels {
    margin-inline-end: 0%;
    margin-top: 5%;
    width: 50%;
  }
  .footer-div hr {
    width: 80%;
  }
  .footer-div p {
    font-size: 16px;
    margin-block: 5%;
  }
  .footer-div {
    height: 400px;
  }
  .footer-div label {
    font-size: 32px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .home-sub-header-div {
    height: 500px;
  }
  .footer-div hr {
    width: 70%;
  }
  .sub-header-labels img {
    width: 80%;
    margin: auto;
  }
  .sub-header-labels {
    margin-inline-end: 5%;
    margin-top: 5%;
  }
}
