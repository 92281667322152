.profile-container {
  width: 70%;
  margin: 0 auto;
  margin-top: 30px;
}
.profile-label {
  font-weight: 600;
  font-size: 36px !important;
  margin-bottom: 0 !important;
}
.profile-div {
  padding: 0 5px;
}
.profile-div label {
  font-weight: 600;
  font-size: 18px;
}
.profile-div img {
  width: 80px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.profile-im .profile-image-div {
  display: grid;
}
.profile-image-div label {
  width: fit-content;
  cursor: pointer;
}
.profile-image-div input {
  display: none;
}
.div-disabled label {
  cursor: default !important;
}
.profile-input-div {
  margin-bottom: 1.5rem;
  text-align: left;
}
.profile-cancel-btn {
  border: 2px solid #8fe139;
  margin-right: 30px;
  color: #8fe139;
  background: #fff;
  border-radius: 30px;
  width: 180px;
  height: 40px;
  font-weight: 600;
  transition: all 0.5s ease-out 0s;
  line-height: normal;
}
.profile-save-btn {
  background: #8fe139;
  color: #fff;
  border: none;
  border-radius: 30px;
  width: 180px;
  height: 40px;
  font-weight: 600;
  transition: all 0.5s ease-out 0s;
  line-height: normal;
}
.profile-save-btn:hover {
  cursor: pointer;
  color: black;
}
.profile-cancel-btn:hover {
  cursor: pointer;
  color: black;
}
.profile-save-btn:focus {
  border: none;
  outline: none;
}
.profile-cancel-btn:focus {
  border: 2px solid #8fe139;
  outline: none;
}
.profile-input-div input {
  position: relative;
  height: 44px;
  border: 0px solid #ced4da;
  background: #f3f4f4;
  border-radius: 0;
}
.profile-input-div select {
  position: relative;
  height: 44px;
  border: 0px solid #ced4da;
  background: #f3f4f4;
  border-radius: 0;
}
.profile-btn-div {
  display: flex;
  justify-content: flex-end;
  margin-block: 7%;
}
.profile-image-label img {
  width: 130px;
  height: 130px;
  background-size: cover;
  background-position: top center;
  border-radius: 50%;
}
.profile-mobile-div {
  width: 100%;
  display: flex;
}
.profile-image-div {
  display: grid;
}
.email-mobile-div {
  display: flex;
  justify-content: space-between;
}
.profile-edit-btn {
  background: #8fe139;
  color: #fff;
  margin-left: 5%;
  border: none;
  border-radius: 30px;
  width: 180px;
  height: 40px;
  font-weight: 600;
  transition: all 0.5s ease-out 0s;
  line-height: normal;
}
.profile-edit-btn:hover {
  border: none;
  outline: none;
  color: #000;
}
.profile-edit-btn:focus {
  border: none;
  outline: none;
}
.modal-btn-div {
  display: flex;
  justify-content: space-between;
  margin-block: 7%;
}
.edit-modal-body {
  margin-top: 5%;
  padding: 1rem 2rem;
}
.disable-btn-profile {
  background: #cccccc !important;
  pointer-events: none;
}
.disable-cancel-btn {
  color: #cccccc !important;
  border: 2px solid #cccccc !important;
  pointer-events: none;
}
.disable-cancel-btn:hover {
  cursor: unset !important;
}
.otp-modal-div input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.otp-modal-div input {
  color: #000;
  border-radius: 5px;
  width: 50px !important;
  height: 50px !important;
  border: 2px solid #c7c7c7;
}
.otp-modal-div input:focus {
  outline: 0;
  color: #000;
  box-shadow: 0 0 0 0 !important;
  border: 2px solid #8fe139 !important;
}
.otp-label-div {
  margin-bottom: 1.5rem;
  text-align: left;
  font-size: 22px;
  font-weight: 600;
}
.resend-first-label {
  font-weight: 400;
}
.resend-otp-modal {
  text-align: left;
  margin: 7% 0% 0% 0%;
}
.fixed-field-div div {
  width: 50px !important;
  margin-right: -12px !important;
}
.fixed-field-div input {
  padding: 0.375rem 0rem 0.375rem 0.75rem !important;
}
@media only screen and (min-width: 240px) and (max-width: 480px) {
  .otp-modal-div input {
    width: 40px !important;
    height: 40px !important;
  }
  .resend-otp-modal {
    font-size: 14px;
  }
  .edit-modal-body {
    padding: 1rem 1rem;
  }
}
@media only screen and (min-width: 240px) and (max-width: 349px) {
  .resend-otp-modal {
    margin: 0% 0% 0% 0%;
  }
  .otp-label-div {
    margin-bottom: 1rem;
  }
}
