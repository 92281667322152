.login-link-div span b {
  color: #8fe139;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
}
.m-btm {
  margin-inline-start: 0% !important;
}
.password-div {
  position: relative;
  margin-bottom: 1.5rem;
}
.password-div i {
  position: absolute;
  padding: 10px;
  top: 4px;
  right: 15px;
  color: #796d70;
}
.password-div i:hover {
  cursor: pointer;
}
.margin-b-btn {
  margin-block: 40px;
}
.margin-b-pass {
  margin-bottom: 5px !important;
}
.login-link-div {
  margin-top: -5px;
}
.login-link-div span b:hover {
  cursor: pointer;
  text-decoration: underline;
}
.resend-otp-div {
  text-align: center;
}
.resend-label {
  color: #8fe139;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}
.resend-label:hover {
  cursor: pointer;
  text-decoration: underline;
}
.pass-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 49.18px;
  margin: 2% 0 4%;
}
@media only screen and (min-width: 240px) and (max-width: 479px) {
  .resend-otp-div {
    font-size: 14px;
  }
  .resend-label {
    font-size: 14px;
  }
  .pass-title {
    margin-block: 6%;
    font-size: 22px;
    line-height: unset;
    margin: 15px 0px 15px 0px;
  }
  .change-pass-btn {
    width: 250px !important;
  }
  .pass-div i {
    right: 15px;
  }
  .login-container input {
    font-size: 13px;
  }
  .login-container select {
    font-size: 13px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 677px) {
  .change-pass-btn {
    width: 200px !important;
  }
  .reset-container {
    width: 80%;
  }
}
@media only screen and (min-width: 678px) and (max-width: 1310px) {
  .change-pass-btn {
    width: 300px !important;
  }
  .reset-container {
    width: 60%;
  }
}
