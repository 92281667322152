.header-div {
  justify-content: space-between;
  width: 100%;
  background-color: #0c1830;
  display: flex;
  height: 95px;
  align-items: center;
}
.header-logo {
  margin: 0 0 0 5%;
  width: 120px;
}
.header-btn-div {
  margin: 0 5% 0 0;
}
.logout-button-div {
  margin-block: auto;
  margin-inline-end: 5%;
}
.sub-header-div {
  width: 100%;
  height: 576px;
  background-size: 100% 100%;
  background: url("../../../assets/exam-images/HomeSubHeader.png");
  display: flex;
  align-items: center;
  background-size: 100% 100%, cover;
}
.sub-header-div label {
  font-weight: 600;
  font-size: 40px;
  color: white;
  margin-inline-start: 10%;
}
.about-us-div {
  text-align: center;
  margin-block-start: 2%;
}
.about-us label {
  font-weight: 600;
  font-size: 40px;
}
.about-us {
  display: flex;
  justify-content: space-between;
  background: #e4edf2;
  padding: 2%;
  margin: 1%;
  width: -webkit-fill-available;
  text-align: center;
}
.about-us p {
  text-align: left;
  font-size: 17px;
}
.about-us-text {
  margin-block: auto;
  width: 60%;
}
.mission-div {
  background: #e5eeff;
  display: flex;
  justify-content: space-evenly;
  padding: 5% 5% 5% 5%;
  margin-block-start: 4%;
}
.services-div {
  width: 100%;
  background-color: #0c1830;
  display: flex;
  justify-content: center;
}
.services-div label {
  font-size: 40px;
  color: #7be04c;
  font-weight: 600;
  margin-bottom: 0rem;
  margin: 2%;
}
.ride-delivery-div {
  background-color: #d7dae3;
  display: flex;
  padding-inline-end: 3%;
}
.rides-div img {
  margin-bottom: 15%;
}
.delivery-div img {
  margin-bottom: 15%;
}
.rides-div {
  text-align: center;
  background: #d0d2de;
  border-top: 150px solid #d0d2de;
  padding-inline-start: 3%;
}
.delivery-div {
  margin-block: auto;
  text-align: center;
}
.rides-div p {
  text-align: left;
  font-size: 17px;
}
.delivery-div p {
  text-align: left;
  font-size: 17px;
}
.rides-div i {
  font-size: 15px;
  margin: 2% 3% 0% 3%;
}
.delivery-div i {
  font-size: 15px;
  margin: 2% 3% 0% 3%;
}
.i-p-div {
  display: flex;
}
.start-button-div {
  text-align: center;
  margin: 3%;
}
.start-button-div button {
  background: #8fe139;
  color: white;
  border: none;
  margin-bottom: 20px;
  margin-block: auto;
  padding: 15px 70px;
  border-radius: 30px;
  width: 300px;
  font-weight: 600;
}
.start-button-div button:hover {
  color: black;
  cursor: pointer !important;
}
.start-button-div button:focus {
  outline: none !important;
  outline: 0;
  transition: all 0.5s ease-out 0s;
  color: #000000;
}
@media only screen and (min-width: 240px) and (max-width: 479px) {
  .header-logo {
    width: 130px;
    object-fit: contain;
    padding: 20px;
  }
  .start-button-div button {
    width: auto;
    padding: 0px;
    height: 40px;
    width: 200px;
  }
  .sub-header-div {
    height: 200px;
  }
  .sub-header-div label {
    font-size: 17px;
  }
  .about-us label {
    font-size: 22px;
  }
  .about-us-div label {
    font-size: 25px;
  }
  .about-us {
    display: block;
    /* width: 90%; */
    margin: auto;
  }
  .about-us-text {
    width: 100%;
  }
  .about-us img {
    width: 240px;
  }
  .about-us p {
    font-size: 14px;
  }
  .mission-div {
    display: block;
  }
  .services-div label {
    font-size: 30px;
  }
  .ride-delivery-div {
    background-color: #d7dae3;
    display: block;
  }
  .header-div {
    height: 75px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .mission-div {
    display: block;
  }
  .margin-auto {
    margin-block: auto;
  }
  .about-us p {
    font-size: 13px;
  }
  .start-button-div button {
    width: auto;
  }
  .about-us img {
    width: 200px;
  }
  .about-us-div label {
    font-size: 40px;
  }
  .header-logo {
    width: 160px;
    object-fit: contain;
    padding: 20px;
  }
  .sub-header-div {
    height: 300px;
  }
  .sub-header-div label {
    font-size: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .sub-header-div {
    height: 400px;
  }
  .sub-header-div label {
    font-size: 30px;
  }
  /* .mobile-banner img{
		width: 350px;
		height: 350px;
	} */
}
@media only screen and (min-width: 240px) and (max-width: 1230px) {
  .mobile-banner img {
    width: 100%;
  }
  .ride-delivery-div {
    background-color: #d7dae3;
    display: inline-block;
    width: 100%;
    padding-inline-end: 0;
  }
  .rides-div {
    text-align: center;
    background: #d0d2de;
    border-top: 0px solid #d0d2de;
    padding-inline-start: 0;
    padding-block-start: 5%;
  }
  .rides-div img {
    margin-bottom: 5%;
  }
  .i-p-div {
    display: flex;
    width: 90%;
    margin: 0 auto;
  }
  .delivery-div {
    margin-block: auto;
    text-align: center;
    padding-block-start: 5%;
    padding-block-end: 5%;
  }
  .delivery-div img {
    margin-bottom: 5%;
  }
  .rides-div i {
    font-size: 15px;
    margin: 0% 3% 0% 3%;
    line-height: 1.7;
  }
  .delivery-div i {
    font-size: 15px;
    margin: 0% 3% 0% 3%;
    line-height: 1.7;
  }
}
