#root {
  width: -webkit-fill-available;
}

.loading-indicator {
  display: flex;
  justify-content: center;
}

.loading-indicator:before {
  content: "";
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: "";
  position: fixed;
  top: 30%;
  font-size: 1.5rem;
  height: 300px;
  width: 300px;
  background-image: url("../assets/VooyLoader.gif");
  background-size: 100% 100%;
  z-index: 1070;
}

@media only screen and (min-width: 210px) and (max-width: 1029px) {
  .loading-indicator:after {
    content: "";
    position: fixed;
    top: 30%;
    margin-inline-end: auto;
    z-index: 1000;
    height: 200px;
    width: 200px;
  }
}
