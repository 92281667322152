.doc-tab label {
  margin-bottom: 0;
  border: 1px solid darkgrey;
  border-radius: 5px;
  width: 95%;
  padding: 1.5% 1.5% 1.5% 3.5%;
  font-size: 18px;
  font-weight: 600;
  text-align: start;
}
.doc-tab {
  margin-bottom: 25px;
  align-self: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  text-align: right;
}
.dashboard-container {
  margin: auto;
  width: 80%;
}
.document-main-div {
  width: 70%;
  margin: 0 auto;
  margin-top: 30px;
  padding: 0 5px;
}
.csd-password-div {
  margin-inline-start: 8%;
  margin-top: 3%;
}
.mobility-upload {
  display: flex !important;
  justify-content: space-between;
  margin-bottom: 3%;
}
.document-hr {
  margin-bottom: 5%;
}
.doc-vehicle-btm {
  margin-bottom: 5px !important;
}
.document-section {
  margin-bottom: 5%;
}
.text-span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.disabled-alignment {
  text-align: left !important;
}
.vehicle-title-disabled {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
  width: 100%;
  text-align: left;
}
.upload-btn-span {
  display: flex;
}
.file-name {
  margin: auto 5px 5px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
}
.file-name img {
  margin-left: 5px;
  display: block;
  margin-top: 3px;
}
.rejected-div {
  margin-top: 5px;
}
.approved-active-div {
  margin-top: 5px;
}
.waiting-div {
  margin-top: 5px;
}
.rejected-div span {
  display: block;
  padding: 3px 15px 3px 15px;
  border-radius: 5px;
  border: 1px solid #e0393b;
  background: rgba(224, 57, 59, 0.3);
}
.approved-active-div span {
  display: block;
  padding: 3px 15px 3px 15px;
  border-radius: 5px;
  border: 1px solid #8fe139;
  background: rgba(143, 225, 57, 0.3);
}
.waiting-div span {
  display: block;
  padding: 3px 15px 3px 15px;
  border-radius: 5px;
  border: 1px solid #e09b39;
  background: rgba(224, 155, 57, 0.3);
}
.expiring-soon-div span {
  display: block;
  padding: 3px 15px 3px 15px;
  border-radius: 5px;
  border: 1px solid #21adff;
  background-color: #98d6fa;
}
.pending-to-go span {
  display: block;
  padding: 3px 15px 3px 15px;
  border-radius: 5px;
  border: 1px solid #c7c7c7;
  background-color: rgba(199, 199, 199, 0.1);
}
.image-status-div {
  display: flex !important;
  justify-content: space-between;
}
.upload-image-active-account {
  text-decoration: underline;
  font-size: 14px;
  color: #8fe139;
  text-decoration: none;
  border: 2px dashed #8fe139;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px 15px 8px 10px;
  white-space: nowrap;
  background: rgba(143, 225, 57, 0.1);
  width: -moz-fit-content;
  width: fit-content;
}
.upload-image-active-account:hover {
  cursor: pointer;
}
.upload-image-active-account img {
  margin-left: 10px;
  height: 18px;
}
.curp-link:hover {
  color: #8fe139;
}
.arrow-icon-down {
  float: right;
  line-height: unset;
  transform: rotateY(0deg) rotate(90deg);
  transition: transform 1s;
  color: #8fe139;
}
.arrow-icon-right {
  float: right;
  line-height: unset;
  transform: rotateY(0deg) rotate(0deg);
  transition: transform 1s;
}
.upload-phase2-images-div {
  display: grid !important;
  border-bottom: 0.8px solid #c7c7c7;
  max-width: 100% !important;
  padding-bottom: 3% !important;
  padding: 0;
  margin: 4% 4% 4% 4% !important;
}
.pload-images-phase-div {
  display: grid !important;
  margin: 4% 4% 0 4%;
  border-bottom: 0.8px solid #c7c7c7;
}
.tax-doc-img {
  margin-inline-start: 0%;
}
.uploaded_doc_image_preview {
  position: relative;
  color: black;
  display: inline-grid;
  float: left;
}
.uploaded_doc_image_preview::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.list-label label {
  margin-top: 1%;
  font-size: 14px !important;
  font-weight: 600;
}
.list-label li {
  font-size: 14px !important;
  font-weight: 600;
}
.doc-tax {
  margin-top: 0px !important;
  margin-block: auto;
}
.choose-doc-tax-div {
  width: inherit !important;
  text-align: left !important;
}
.choose-doc-tax-div input[type="file"] {
  width: -webkit-fill-available;
  display: none !important;
}
.upload-doc-div {
  display: grid;
}
.upload-doc-div img {
  padding-bottom: 5px;
  padding-inline-start: 10%;
}
.align-remark {
  text-align: start !important;
}
.align-remark span {
  font-size: 13px;
}
.expiry-date {
  display: flex !important;
}
.rejected-remark-doc {
  color: red;
  font-weight: 600;
}
.proof-of-tax-div {
  display: grid;
}
.main-attachment-div {
  display: block;
}
.upload-div-width {
  max-width: 100% !important;
  padding-bottom: 3% !important;
  padding: 0;
}
.tax-doc-div {
  width: 100%;
  display: block !important;
  /* padding: 20px 0px; */
  border-bottom: 0px #a5a3a3 solid !important;
  justify-content: space-between;
}
.doc-label {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 600;
  margin-bottom: 3%;
  margin-inline-start: 4%;
  font-size: 18px;
}
.file-icon {
  color: #8fe139;
  font-size: 50px;
}
.proof-tax-label {
  display: block;
  font-weight: 600;
}
.list {
  font-size: 14px;
  font-weight: 600;
}
.sat-link {
  color: #007bff;
  font-weight: 600;
  text-decoration: underline;
}
.sat-link:hover {
  color: #8fe139;
}
.upload-tooltip-div240 {
  display: flex;
  padding-block: 2%;
  width: 75% !important;
  margin: 0 auto;
  margin-inline-start: 0%;
}
.upload-tooltip-div {
  display: flex;
  width: -webkit-fill-available;
  margin-block: auto;
}
.choose-doc-file-div {
  position: relative;
  color: black;
  width: fit-content;
  text-align: right;
  margin-top: 15px;
  /* margin-inline-start: auto; */
}
.choose-doc-file-div input[type="file"] {
  opacity: 0;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  justify-content: center;
  display: block;
  min-width: 100%;
  height: 100%;
  font-family: "Avenir Next", Regular;
}
.choose-doc-file-div input[type="file"]:hover {
  cursor: pointer;
}
.choose-doc-file-div:hover {
  color: #8fe139;
}
.uploaded-doc-div {
  display: grid;
}
.uploaded-doc-div img {
  padding-bottom: 5px;
  padding-inline-start: 10%;
}
.upload-title-lable {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
  line-height: 1.2;
}
.tax-label {
  display: inline-block !important;
  cursor: pointer !important;
}
.extension-label {
  color: red;
  margin-inline-start: 10px;
}
.tooltip-icon-tax {
  margin-left: 15px;
}
.tooltip-icon-tax i:hover {
  cursor: help;
}
.password-tooltip-width {
  width: 40%;
  text-align: left;
  color: black !important;
  background: #def6c6 !important;
}
.tax-tip-icon {
  width: 15px;
  height: 15px;
  padding: 0px !important;
}
.icon-style {
  color: black !important;
}
.tax-regime-div {
  margin-inline-start: 4%;
}
.tax-regime-div hr {
  border-top: 0.8px solid #c7c7c7;
  margin-top: 5%;
}
.tax-regime-div select {
  height: 44px !important;
  border: 0px solid #ced4da;
  background: #f3f4f4;
  border-radius: 0;
}
.tax-regime-div select:focus {
  box-shadow: 0 0 0 0 !important;
  background: #f3f4f4;
}
.upload-tax-div {
  display: flex;
  line-height: 1;
  margin-bottom: 10px;
}
.upload-tax-div label {
  font-size: 18px;
  font-weight: bold;
}
.upload-regime {
  font-weight: 600;
  font-size: 18px;
}
.password-div-hr hr {
  border-top: 0.8px solid #c7c7c7;
  margin-top: 5%;
  margin-inline-start: 4%;
}
.password-div {
  margin-inline-start: 8%;
}
.password-input-div {
  position: relative !important;
}
.password-input-div i {
  font-size: 13px;
  position: absolute !important;
  right: 15px !important;
  top: 5px !important;
  padding: 10px !important;
  color: grey;
}
.password-input-div i:hover {
  cursor: pointer !important;
}

.password-input-div input {
  position: relative !important;
  height: 44px !important;
  border: 0px solid #ced4da;
  background: #f3f4f4;
  border-radius: 0;
}
.password-input-div input:focus {
  background: #f3f4f4;
  box-shadow: 0 0 0 0 !important;
}
.toxilogical-tooltip {
  display: flex;
}
.curp-input-div-active input {
  width: 100%;
  height: 44px !important;
  border: 0px solid #ced4da;
  background: #f3f4f4;
  border-radius: 0;
}
.curp-input-div-active {
  margin-block: 2%;
}
.doc-m-btm {
  margin-bottom: 30px;
}
.curp-status-div {
  display: flex;
  justify-content: space-between;
}
.document-section label {
  margin-bottom: 0;
  border: 1px solid darkgrey;
  border-radius: 5px;
  width: 100%;
  padding: 1.5%;
  font-size: 18px;
  font-weight: 600;
}
.document-section:hover {
  cursor: pointer;
}
.document-section i {
  margin-right: 5px;
  float: right;
  line-height: unset;
}
.remove-border {
  padding-bottom: 0 !important;
  border-bottom: 0 solid #c7c7c7 !important;
}
.doc-container-m {
  padding-bottom: 15%;
}
.margin-b-upload {
  margin-bottom: 2%;
}
.vehicle-label {
  margin: 3% 0% 4% 0%;
  font-weight: 800;
  font-size: 18px;
  border-bottom: 3px solid #c7c7c7;
  display: block;
  padding-bottom: 10px;
  width: 100%;
  float: right;
}
.upload-proof-of-tax {
  justify-content: space-between;
  margin-inline-start: 4%;
}
.tax-doc-active {
  margin-inline-start: 0%;
  float: unset !important;
}
.proof-tax-doc-active {
  margin-inline-start: 0%;
  width: 100%;
  float: unset !important;
}
.upload-main-active-div {
  display: grid;
  margin-inline-start: 8%;
}
.tooltip-active-div {
  display: flex;
  margin-block: 1%;
}
.tax-upload {
  display: flex !important;
  justify-content: space-between;
  margin-bottom: 1%;
}
.upload-tax-label {
  font-weight: 600;
  font-size: 18px;
  margin-top: 1%;
}
.red {
  color: red;
}
.choose-doc-file-div-disabled {
  position: relative;
  color: black;
  width: -moz-fit-content;
  width: fit-content;
  text-align: right;
  margin-top: 15px;
  display: grid;
}
@media only screen and (min-width: 240px) and (max-width: 479px) {
  .doc-tab label {
    font-size: 12px;
  }
  .document-section {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .dashboard-container {
    width: 90%;
  }
  .upload-tax-div label {
    font-size: 13px;
  }
  .tax-regime-div select {
    font-size: 13px;
  }
  .upload-title-lable {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 3px;
  }
  .upload-tooltip-div240 {
    display: flex;
    padding-block: 2%;
    width: 75% !important;
    margin-inline-start: 0%;
  }
  .uploaded_doc_image_preview::before {
    background-position: center;
  }
  .uploaded_doc_image_preview img {
    object-fit: contain;
    border-radius: 4px;
    width: 153px;
    height: 110px;
    cursor: pointer;
  }
  .uploaded_doc_image_preview {
    width: 100% !important;
    /* margin-inline-start: 8%; */
  }
  .upload-tooltip-div {
    display: flex;
    padding-block: 5%;
    width: 75% !important;
    margin-inline-start: 8%;
  }
  .choose-doc-file-div input[type="file"] {
    height: 60px;
    left: 0px;
    top: 50px;
  }
  .choose-doc-file-div {
    width: 100% !important;
    text-align: center !important;
  }
  .vehicle-label {
    font-size: 16px;
  }
  .tooltip-active-div {
    width: max-content;
  }
  .rejected-div span {
    font-size: 13px;
  }
  .approved-active-div span {
    font-size: 13px;
  }
  .waiting-div span {
    font-size: 13px;
  }
  .expiring-soon-div span {
    font-size: 13px;
  }
  .pending-to-go span {
    font-size: 13px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 533px) {
  .upload-tooltip-div240 {
    display: flex;
    padding-block: 2%;
    width: 75% !important;
    margin-inline-start: 0%;
  }
  .upload-tooltip-div {
    display: flex;
    padding-block: 5%;
    width: 75% !important;
    margin-inline-start: 8%;
  }
  .tax-regime-div select {
    font-size: 13px;
  }
  .dashboard-container {
    width: 70%;
  }
}

@media only screen and (min-width: 777px) and (max-width: 1149px) {
  .dashboard-container {
    width: 60%;
  }
}
