.course-content-list {
  width: 25%;
  background-color: #d7dae3;
  /* height: 100vh; */
  height: auto;
  overflow-y: auto;
  overflow-wrap: anywhere;
}
.course-content-list label {
  padding: 0 0 0 10%;
  font-size: 17px;
  margin-bottom: 0rem;
  padding-block: 3%;
  width: -webkit-fill-available;
}
.course-content-list label:hover {
  color: white;
  cursor: pointer;
}
.label-selected {
  background: #7be04c;
  color: white;
  width: -webkit-fill-available;
  padding: 5% 0% 5% 10% !important;
}
.player-wrapper {
  background: #f3f4f4;
  justify-content: center;
  display: block;
  overflow: hidden;
  width: 80%;
  float: right;
}
.body-div {
  display: flex;
}
.exit-button-div {
  width: 20%;
}
.react-player {
  margin: auto;
}
.video-title-div {
  width: 90%;
  margin: auto;
  margin-block: 5%;
}
.exit-button-div button {
  background: #e5eeff;
  color: #344058;
  border: none;
  width: -webkit-fill-available;
  padding: 12% 5% 12% 5%;
  font-weight: 600;
  font-size: 17px !important;
  margin-block: auto;
}
.exit-button-div button:hover {
  cursor: pointer !important;
}
.exit-button-div button:focus {
  outline: none !important;
  outline: 0;
  transition: all 0.5s ease-out 0s;
  color: #000000;
}
.exit-button-div i {
  color: #344058;
  margin-inline-end: 5%;
  font-size: 20px;
}
.video-title-div label {
  font-weight: 500;
  font-size: 24px;
}
.next-button-div {
  display: flex;
  justify-content: flex-end;
  width: 90%;
  margin: auto;
  margin-block-end: 10%;
}
.next-button-div button {
  background: #7be04c;
  border: none;
  color: white;
  padding: 10px 30px 10px 30px;
  border-radius: 30px;
  font-weight: 600;
  width: 180px;
  font-size: 17px !important;
}
.next-button-div button:hover {
  color: black;
  cursor: pointer !important;
}
.next-button-div button:focus {
  outline: none !important;
  outline: 0;
  transition: all 0.5s ease-out 0s;
  color: #000000;
}
.pre-next-button-div {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  margin-block-end: 10%;
}
.pre-next-button-div button {
  background: #7be04c;
  border: none;
  padding: 10px 30px;
  border-radius: 30px;
  font-weight: 600;
  width: 180px;
  font-size: 17px !important;
  color: white;
}
.pre-next-button-div button:hover {
  cursor: pointer !important;
  color: black;
}
.pre-next-button-div button:focus {
  outline: none !important;
  outline: 0;
  transition: all 0.5s ease-out 0s;
  color: #000000;
}
.previous-button {
  background: #c4c4c4 !important;
  color: white !important;
  font-size: 17px !important;
}
.previous-button:hover {
  color: black !important;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav label {
    font-size: 18px;
  }
}
@media only screen and (min-width: 240px) and (max-width: 479px) {
  .body-div {
    display: inline-block;
    width: -webkit-fill-available;
  }
  .pre-next-button-div button {
    font-size: 12px !important;
    width: auto;
  }
  .exit-button-div {
    width: 100%;
  }
  .exit-button-div button {
    padding: 5%;
  }
  .course-content-list {
    width: auto;
    background-color: #d7dae3;
    display: flex;
    overflow-x: auto;
    height: auto;
    overflow-wrap: anywhere;
  }
  .course-content-list label {
    font-size: 14px;
    padding: 5% !important;
    flex-shrink: 0;
    width: auto;
  }
  .player-wrapper {
    background: #f3f4f4;
    justify-content: center;
    display: block;
    overflow: hidden;
    width: 100%;
  }
  .video-title-div label {
    font-weight: 500;
    font-size: 16px;
  }
  .react-player {
    margin: auto;
    width: 85% !important;
    height: 250px !important;
  }
  .video-title-div p {
    font-size: 14px;
  }
  .next-button-div button {
    padding: 10px 25px 10px 25px;
    font-size: 12px !important;
    width: auto;
  }
}
@media only screen and (min-width: 480px) and (max-width: 879px) {
  .body-div {
    display: inline-block;
    width: -webkit-fill-available;
  }
  .exit-button-div {
    width: 100%;
  }
  .exit-button-div button {
    padding: 5%;
  }
  .course-content-list {
    width: auto;
    background-color: #d7dae3;
    display: flex;
    height: auto;
    overflow-x: auto;
    overflow-wrap: anywhere;
  }
  .course-content-list label {
    font-size: 16px;
    margin-bottom: 0rem;
    padding: 3% !important;
    flex-shrink: 0;
    width: auto;
  }
  .player-wrapper {
    background: #f3f4f4;
    justify-content: center;
    display: block;
    overflow: hidden;
    width: 100%;
  }
  .video-title-div label {
    font-weight: 500;
    font-size: 24px;
  }
  .react-player {
    margin: auto;
    width: 85% !important;
    height: 450px !important;
  }
  .video-title-div p {
    font-size: 16px;
  }
  .next-button-div button {
    padding: 10px 25px 10px 25px;
    font-size: 17px !important;
  }
}
@media only screen and (min-width: 880px) and (max-width: 2000px) {
  .course-content-list::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}
